<template>
  <div class="switch-mode" :class="{ vertical }">
    <div class="switch-mode_wrap">
      <div class="switch-mode__overlay" :class="{ move: !dark }" />
      <button class="switch-mode__item" :class="{ active: dark }" @click.prevent="toggle(true)">
        <FontIcon icon="night" size="20" />
      </button>
      <button class="switch-mode__item" :class="{ active: !dark }" @click.prevent="toggle(false)">
        <FontIcon icon="day" size="20" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";
const store = useStore();
const { dark } = storeToRefs(store);
const toggle = (val) => {
  dark.value = val;
  localStorage.setItem("dark", val);
};
const props = defineProps({
  vertical: {
    type: Boolean,
    default: false
  },
})
</script>

<style lang="scss">
.switch-mode {
  background: var(--primary-bg);
  border-radius: 6px;
  padding: 4px;

  &_wrap {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__overlay {
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--primary-700);
    transition: .3s;

    &.move {
      left: 36px;
    }
  }

  &__item {
    width: 32px;
    min-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    position: relative;
    background: none;

    svg {
      color: var(--primary-300);
    }

    &.active {
      svg {
        color: #fff;
      }
    }
  }

  &.vertical {
    .switch-mode {
      &_wrap {
        flex-direction: column;
      }

      &__overlay {
        &.move {
          left: 0;
          top: 36px;
        }
      }
    }
  }
}
</style>
