<template>
  <div class="mobmenu" :class="{ open: showMenu }">
    <div class="mobmenu_overlay" @click="$emit('show-menu')" />
    <div class="mobmenu_wrap">
      <div class="mobmenu__inner">
        <div class="mobmenu-games">
          <div class="mobmenu-games_section" :class="{ open: toggleCasino }">
            <button class="mobmenu-games--top" @click="toggleCasino = !toggleCasino">
              <FontIcon icon="casino05" size="20" />
              <span>Casino</span>
              <FontIcon icon="arrow" class="arrow" size="20" />
            </button>
            <div class="mobmenu-games--bottom">
              <NuxtLink :to="localePath(`/casino/slots`)" class="mobmenu-games__item">
                <FontIcon icon="slots" size="20" />
                <span>Slots</span>
              </NuxtLink>
              <NuxtLink :to="localePath(`/casino/live`)" class="mobmenu-games__item">
                <FontIcon icon="live" size="20" />
                <span>Live Games</span>
              </NuxtLink>
            </div>
          </div>
          <div class="mobmenu-games_section" :class="{ open: toggleGames }">
            <button class="mobmenu-games--top" @click="toggleGames = !toggleGames">
              <FontIcon icon="trixGames" size="20" />
              <span>Trix Games</span>
              <FontIcon icon="arrow" class="arrow" size="20" />
            </button>
            <div class="mobmenu-games--bottom">
              <NuxtLink :to="localePath(`/tower`)" class="mobmenu-games__item">
                <FontIcon icon="tower" size="20" />
                <span>Tower</span>
              </NuxtLink>
              <NuxtLink :to="localePath(`/mines`)" class="mobmenu-games__item">
                <FontIcon icon="mines" size="20" />
                <span>Mines</span>
              </NuxtLink>
              <NuxtLink :to="localePath(`/dice`)" class="mobmenu-games__item">
                <FontIcon icon="dice" size="20" />
                <span>Classic Dice</span>
              </NuxtLink>
              <NuxtLink :to="localePath(`/x100`)" class="mobmenu-games__item">
                <FontIcon icon="x100" size="20" />
                <span>X100</span>
              </NuxtLink>
              <NuxtLink :to="localePath(`/dice/bonus`)" class="mobmenu-games__item">
                <FontIcon icon="dice" size="20" />
                <span>Bonus Dice</span>
              </NuxtLink>
              <NuxtLink :to="localePath(`/bubbles`)" class="mobmenu-games__item">
                <FontIcon icon="bubbles" size="20" />
                <span>Bubbles</span>
              </NuxtLink>
              <NuxtLink :to="localePath(`/megawheel`)" class="mobmenu-games__item">
                <FontIcon icon="wheel" size="20" />
                <span>Mega Wheel</span>
              </NuxtLink>
              <NuxtLink :to="localePath(`/popit`)" class="mobmenu-games__item">
                <FontIcon icon="popit" size="20" />
                <span>Popit</span>
              </NuxtLink>
              <NuxtLink :to="localePath(`/mainx`)" class="mobmenu-games__item">
                <FontIcon icon="bonusBuy" size="20" />
                <span>Money Train</span>
              </NuxtLink>
              <NuxtLink :to="localePath(`/hotline`)" class="mobmenu-games__item">
                <FontIcon icon="hotline" size="20" />
                <span>Hotline</span>
              </NuxtLink>
            </div>
          </div>
        </div>
        <div class="mobmenu-nav">
          <!-- <button class="mobmenu-nav_item" @click.prevent="$vfm.show('rakeback')">
            <img src="/images/icons/rakeback.svg" alt="" />
            <span>Rakeback</span>
          </button> -->
          <button v-if="canCreateGiveaway" class="mobmenu-nav_item" @click.prevent="$vfm.show('giveaway_create')">
            <img src="/images/icons/giveaway.svg" alt="" width="20" />
            <span>{{ $t("header.giveaway") }}</span>
          </button>
          <NuxtLink :to="localePath(`/tournaments`)" class="mobmenu-nav_item">
            <img src="/images/icons/cup.svg" alt="" />
            <span>{{ $t("header.tournaments") }}</span>
          </NuxtLink>
          <NuxtLink :to="localePath(`/refs`)" class="mobmenu-nav_item">
            <img src="/images/icons/affiliate.svg" alt="" />
            <span>{{ $t("header.referral") }}</span>
          </NuxtLink>
          <!-- <NuxtLink :to="localePath(`/tournaments`)" class="mobmenu-nav_item">
            <img src="/images/icons/cup.svg" alt="" />
            <span>{{ $t("header.tournaments") }}</span>
          </NuxtLink>
          <NuxtLink :to="localePath(`/bonus`)" class="mobmenu-nav_item">
            <img src="/images/icons/promo.svg" alt="" />
            <span> {{ $t("header.bonuses") }}</span>
          </NuxtLink> -->
          <NuxtLink :to="localePath(`/faq`)" class="mobmenu-nav_item">
            <img src="/images/icons/faq.svg" alt="" />
            <span>FAQ</span>
          </NuxtLink>
          <NuxtLink :to="localePath(`/support`)" class="mobmenu-nav_item">
            <img src="/images/icons/support.svg" alt="" />
            <span>{{ $t("header.support") }}</span>
          </NuxtLink>
        </div>
        <div class="mobmenu-footer">
          <VLanguage />
          <VColorMode />
          <ViewMode />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { $vfm } from "vue-final-modal";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";

const store = useStore();
defineEmits(["show-menu"]);
const { is_collected } = storeToRefs(store);
const localePath = useLocalePath();

const toggleCasino = ref(true);
const toggleGames = ref(false);

const props = defineProps({
  showMenu: {
    type: Boolean,
    default: false,
  },
});
const { $auth } = useNuxtApp();
const canCreateGiveaway = computed(() => {
  return $auth.loggedIn && $auth.user.permissions.includes("giveaway:create");
});
</script>

<style lang="scss">
.mobmenu {
  opacity: 0;
  transition: 0.3s;
  position: fixed;
  z-index: 900;
  color: var(--primary-800);
  font-weight: 600;
  font-size: 14px;

  a,
  button {
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
  }

  button.mobmenu-nav_item {
    justify-content: flex-start;
  }

  &.open {
    opacity: 1;

    .mobmenu {
      &_overlay {
        opacity: 0.3;
        visibility: visible;
      }

      &_wrap {
        transform: unset;
      }
    }
  }

  &_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 900;
    background: #252433;
    opacity: 0;
    visibility: hidden;

    @media screen and (min-width: 1350px) {
      display: none;
    }
  }

  &_wrap {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 290px;
    background: var(--primary-200);
    z-index: 900;
    transform: translateX(-100%);
    transition: 0.3s;
    top: 76px;

    @media screen and (max-width: 1500px) {
      top: 0;
    }

    @media screen and (max-width: 980px) {
      top: 55px;
      padding-bottom: 64px;
    }
  }

  &__inner {
    overflow-y: auto;
    height: 100%;
  }

  &-games {
    background: var(--primary-bg);
    padding: 20px 12px;
    display: grid;
    gap: 8px;

    svg {
      color: var(--primary-500);
    }

    &_section {
      overflow: hidden;
      max-height: 44px;
      transition: 0.3s;

      &.open {
        max-height: 500px;

        .arrow {
          transform: unset;
        }
      }
    }

    &--top {
      width: 100%;
      height: 44px;
      border-radius: 6px;
      padding: 3px 12px;
      display: flex;
      align-items: center;
      gap: 12px;
      background: var(--primary-100);
      transition: background 0.2s;

      .arrow {
        margin-left: auto;
        transform: rotate(-90deg);
        transition: 0.3s;
      }

      &:hover {
        background: var(--primary-400);
      }
    }

    &--bottom {
      padding-top: 8px;
      display: grid;
      gap: 8px;
    }

    &__item {
      width: 100%;
      height: 36px;
      border-radius: 6px;
      padding: 3px 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      background: transparent;
      transition: background 0.2s;

      &:hover {
        background: var(--primary-400);
      }

      &.router-link-active {
        background: var(--primary-100);
      }
    }
  }

  &-nav {
    padding: 20px 12px;
    display: grid;
    gap: 8px;

    &_item {
      width: 100%;
      height: 48px;
      border-radius: 6px;
      padding: 3px 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      background: var(--primary-bg);
      transition: background 0.2s;
      img {
        width: 20px;
        height: auto;
      }

      &:hover {
        background: var(--primary-400);
      }

      &.router-link-active {
        background: var(--primary-400);
      }
    }
  }

  &-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    padding: 0 12px 20px;

    .lang {
      flex-grow: 1;
      max-width: 300px;
    }
  }

  @media screen and (min-width: 1350px) {
    top: 68px;
    bottom: 0;

    &_wrap {
      position: unset;
      height: 100%;
    }
  }
}
</style>
