<template>
  <div class="plitka-view" :class="{ toggled: !oldHome }">
    <img src="/images/icons/tile.svg" alt="" />
    <p>{{ $t('header.tiled') }}</p>
    <div class="plitka-view_swicher" :class="{ toggled: !oldHome }" @click.prevent="toggle(!oldHome)" />
  </div>
</template>

<script setup>
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";
const store = useStore();
const { oldHome } = storeToRefs(store);
const toggle = (val) => {
  oldHome.value = val;
};
</script>

<style lang="scss">
.plitka-view {
  background: var(--primary-bg);
  border-radius: 6px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 40px;
  width: 100%;

  p {
    flex-grow: 1;
  }

  &_swicher {
    background: var(--primary-200);
    border-radius: 12px;
    width: 40px;
    height: 20px;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background: var(--primary-700);
      border-radius: 100px;
      position: absolute;
      top: 0;
      left: 0;
      transition: .3s;
    }

    &.toggled {
      &:after {
        left: 20px;
      }
    }
  }

  &.sm {
    padding: 0;
    width: 40px;
    justify-content: center;

    p {
      display: none;
    }

    .plitka-view {
      &_swicher {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}
</style>
