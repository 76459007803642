<template>
  <div
    v-if="availableLocales"
    ref="dd"
    class="cselect ontop lang"
    :class="{ open: toggle }"
  >
    <button class="cselect_top btn-black language-selection" @click="toggle = !toggle">
      <img :src="`/icons/${locale}.svg`" />
      <span>{{ titles[locale] }}</span>
      <FontIcon icon="arrow" size="16" class="arrow" />
    </button>
    <div class="cselect_dropdown">
      <div class="cselect_overlay" @click="toggle = !toggle" />
      <div class="list cselect__list">
        <button
          v-for="l in availableLocales"
          :key="l.code"
          class="btn"
          :class="{ active: locale === l.code }"
          @click="selectLanguage(l)"
        >
          <img :src="`/icons/${l.code}.svg`" />
          <span>{{ titles[l.code] }}</span>
          <FontIcon v-if="locale === l.code" icon="check" size="16" class="check" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
const i18n = useI18n();
const { locale } = useI18n();
const localePath = useLocalePath();
const titles = {
  ru: "Русский",
  en: "English",
};
const availableLocales = i18n.locales;
const toggle = ref(false);
const dd = ref(null);
const route = useRoute();
const router = useRouter();
const selectLanguage = (language) => {
  i18n.setLocale(language.code);
  toggle.value = false;
  router.push(localePath(route.path, language.code));
};
const documentClick = (event) => {
  let target = event.target;
  if (dd && dd.value && dd.value !== target && !dd.value.contains(target)) {
    toggle.value = false;
  }
};
onMounted(() => {
  document.addEventListener("click", documentClick);
});
onBeforeUnmount(() => {
  document.removeEventListener("click", documentClick);
});
</script>

<style lang="scss">
@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lang {
  position: relative;
  font-size: inherit;
  max-width: 120px;

  .arrow,
  .check {
    color: var(--primary-300);
  }

  & > .cselect {
    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      padding: 1px 16px;
      min-height: 40px;
      border-radius: 6px;
      font-weight: 500;
      width: 100%;
      @include ellipsis;
      transition: 0.3s;
      background: var(--primary-bg);
      text-align: left;

      span {
        width: 100%;
        color: var(--primary-800) !important;
      }

      .arrow {
        transition: 0.3s;
      }

      &.transparent {
        background: transparent !important;
      }
    }

    &_dropdown {
      position: absolute;
      z-index: 50;
      top: 110%;
      left: -10px;
      right: -10px;
      padding: 10px;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      min-width: 100%;

      .list {
        background: var(--primary-bg);
        border-radius: 6px;
        padding: 3px;
        overflow-y: auto;
        max-height: 430px;
        box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);

        .btn {
          @include ellipsis;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 12px;
          font-weight: 500;
          width: 100%;
          background: none;
          gap: 8px;
          text-align: left;

          span {
            width: 100%;
            color: var(--primary-800) !important;
          }

          &:hover {
            background: var(--primary-100);
          }

          &.active {
            background: var(--primary-100);
          }
        }
      }
    }
  }

  &.open {
    & > .cselect {
      &_top {
        .arrow {
          transform: rotate(180deg);
        }
      }

      &_dropdown {
        top: 100%;
        opacity: 1;
        visibility: visible;
      }
    }

    &.ontop {
      & > .cselect {
        &_dropdown {
          top: inherit;
          bottom: 100%;
        }
      }
    }
  }

  &.ontop {
    & > .cselect {
      &_dropdown {
        top: inherit;
        bottom: 90%;
      }
    }
  }
}

.theme__dark {
  .lang {
    .language-selection,
    .cselect__list .btn,
    .cselect__list .btn span {
      color: #fff !important;
    }
  }
}
</style>
